<template>
  <div>
    <van-cell-group>
      <van-cell title="社区"
        :value="hzlist.OrgName" />
      <van-cell title="小区"
        :value="hzlist.RName" />
      <van-cell title="房号"
        :value="hzlist.RoomCode" />
      <van-cell title="姓名"
        :value="hzlist.Name" />
      <van-cell title="身份证号"
        :value="hzlist.IDCard" />
      <van-cell title="联系方式"
        :value="hzlist.Mobile" />
      <van-cell title="性别"
        :value="hzlist.SexDesc" />
      <van-cell title="民族"
        :value="hzlist.NationDesc" />
      <van-cell title="住址"
        :value="hzlist.Address" />

      <van-cell title="预约时间"
        :value="hzlist.OrderTime | capitalizeTime" />
      <van-cell title="报备时间"
        :value="hzlist.AddTime" />
      <van-cell title="备注"
        :value="hzlist.Remark" />
    </van-cell-group>
  </div>
</template>
<script>
import { WxGetReporting } from "@/api/fangyi";
import { getwgToken } from "@/utils/auth";
import { ImagePreview } from "vant";
export default {
  name: "ImagePreview ",
  data () {
    return {
      listfrom: {},
      hzlist: [],
      url: "https://lyxnyapi.dexian.ren/",
      HealthCodeUrl: [],
    };
  },
  created () {
    this.cyXq();
  },
  filters: {
    capitalizeTime: function (value) {
      if (!value) return "";
      let gstime = value.substring(0, 10);
      return gstime;
    },
  },
  methods: {
    // 健康码
    openHealth: function (row) {
      console.log(row);
      ImagePreview({
        // images:this.newImgUrl,
        images: [row],
        startPosition: 0,
        closeOnPopstate: true,
      });
    },
    // 行程码
    TravelHealth: function (row) {
      console.log(row);
      ImagePreview({
        // images:this.newImgUrl,
        images: [row],
        startPosition: 0,
        closeOnPopstate: true,
      });
    },
    // 核酸证明
    TestifyHealth: function (row) {
      console.log(row);
      ImagePreview({
        // images:this.newImgUrl,
        images: [row],
        startPosition: 0,
        closeOnPopstate: true,
      });
    },
    // 获取住户列表
    cyXq () {
      //   console.log(this.$route);
      //   console.log(JSON.parse(this.$route.params.itemmark));
      this.hzlist = JSON.parse(this.$route.params.id);
      console.log(this.hzlist);
      this.HealthCodeUrl = this.url + this.hzlist.HealthCode
      // console.log(this.HealthCodeUrl);
      //   WxGetReporting({ id: this.$route.params.id })
      //     .then((res) => {
      //       if (res.data.code == 0) {
      //         if (res.data.count == 0) {
      //           this.hzlist = {};
      //         } else {
      //           this.hzlist = res.data.data;
      //         }
      //       }
      //     })
      //     .catch(() => { });
    },
  },
};
</script>
<style scoped>
.van-cell {
  font-size: 15px !important;
}
.rightClass {
  width: 60% !important;
}
</style>